<template>
  <div>
    <div class="contact">
      <h3 style="margin-left: 20px">FBS 社交网络</h3>
      <div style="margin-left: 20px">
        <a href=""
          ><img
            style="width: 30px"
            src="../images/pc/8b86e8c5c83a4784aaaff03aa83bb92f.jpeg"
            alt=""
        /></a>
      </div>
      <h3 style="margin-left: 20px">联系我们</h3>
      <div style="margin-left: 20px">
        <a href=""
          ><img
            style="width: 30px"
            src="../images/pc/8b86e8c5c83a4784aaaff03aa83bb92f.jpeg"
            alt=""
        /></a>
        <a style="margin-left: 10px" href=""
          ><img style="width: 30px" src="../images/pc/wechat.jpeg" alt=""
        /></a>
      </div>
    </div>
    <div class="ending">
      <p>
        注册办公地址：HILLSIDE（GAMING）LP（注册编号120），UNIT 1.1, FIRST
        FLOOR, WATERPORT PLACE, 2 EUROPORT AVENUE, GIBRALTAR。
      </p>
      <p>
        HILLSIDE（GAMING）LP是由直布罗陀政府颁发执照并受直布罗陀博彩委员会监管（RGL编号077）。
      </p>
      <p>
        通过进入、继续使用或浏览此网站，您即被认定接受：我们将使用特定的浏览器COOKIES优化您的客户享用体验。365Asia仅会使用优化您服务体验的COOKIES，而不是侵犯您隐私的COOKIES。
      </p>
      <p>
        关于我们使用COOKIES，以及您如何取消、管理COOKIES使用的更多详情，请参考我们的COOKIES政策。
      </p>
      <p style="text-align: center">© 2001-2024 bet365版权所有</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.contact {
  color: #869ca8;
  font-size: 16px;
  font-weight: normal;
  background-color: #000;
}
.contact > h3 {
  margin: 0;
}
.ending {
  width: 100vw;
  background-color: #384147;
  font-size: 12px;
  color: #6b6b6b;
}
.ending > p {
  margin: 0 auto;
  width: 95%;
}
</style>